import routes from "@/routes/routes.mjs";

const index = routes.findIndex((route) => route.key === "val-postmatch");
const route = routes[index];

export function setup() {
  if (route) {
    Object.assign(route, {
      component: "game-val/PostmatchCoaching.jsx",
    });
  }
}

export function teardown() {
  if (route) {
    Object.assign(route, {
      component: "game-val/Postmatch.jsx",
    });
  }
}
